var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: {
        width: "360px",
        "no-click-animation": "",
        persistent: "",
        disabled: _vm.readonly,
      },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function ({ on, attrs }) {
            return [
              _c(
                "v-text-field",
                _vm._g(
                  _vm._b(
                    {
                      ref: "timePicker",
                      attrs: {
                        dense: _vm.dense,
                        disabled: _vm.disabled,
                        error: _vm.error,
                        "hide-details": _vm.hideDetails,
                        hint: _vm.hint,
                        label: _vm.label,
                        outlined: _vm.outlined,
                        "persistent-hint": _vm.persistentHint,
                        required: _vm.required,
                        rules: _vm.rules,
                        "prepend-icon": "mdi-clock-time-four-outline",
                        readonly: "",
                      },
                      on: { click: _vm.resetValidation },
                      model: {
                        value: _vm.showTime,
                        callback: function ($$v) {
                          _vm.showTime = $$v
                        },
                        expression: "showTime",
                      },
                    },
                    "v-text-field",
                    attrs,
                    false
                  ),
                  on
                )
              ),
            ]
          },
        },
      ]),
      model: {
        value: _vm.modal,
        callback: function ($$v) {
          _vm.modal = $$v
        },
        expression: "modal",
      },
    },
    [
      _c(
        "v-card",
        { staticClass: "pt-5" },
        [
          _c("v-card-text", [
            _c(
              "div",
              { staticClass: "d-flex justify-space-between mb-6" },
              [
                _c("v-select", {
                  attrs: {
                    items: _vm.hours,
                    outlined: "",
                    "menu-props": "auto",
                    "append-icon": "",
                  },
                  model: {
                    value: _vm.selectTime.hour,
                    callback: function ($$v) {
                      _vm.$set(_vm.selectTime, "hour", $$v)
                    },
                    expression: "selectTime.hour",
                  },
                }),
                _c(
                  "div",
                  {
                    staticClass: "text-h4",
                    staticStyle: { "padding-top": "10px" },
                  },
                  [_vm._v(":")]
                ),
                _c("v-select", {
                  attrs: {
                    items: _vm.minutes,
                    outlined: "",
                    "menu-props": "auto",
                    "append-icon": "",
                  },
                  model: {
                    value: _vm.selectTime.minute,
                    callback: function ($$v) {
                      _vm.$set(_vm.selectTime, "minute", $$v)
                    },
                    expression: "selectTime.minute",
                  },
                }),
                _c(
                  "v-btn-toggle",
                  {
                    staticStyle: { padding: "4px 0px 0px 2px" },
                    attrs: { mandatory: "" },
                    model: {
                      value: _vm.selectTime.ampm,
                      callback: function ($$v) {
                        _vm.$set(_vm.selectTime, "ampm", $$v)
                      },
                      expression: "selectTime.ampm",
                    },
                  },
                  [
                    _c("v-btn", { attrs: { value: "am" } }, [_vm._v("AM")]),
                    _c("v-btn", { attrs: { value: "pm" } }, [_vm._v("PM")]),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
          _c(
            "v-card-actions",
            [
              _c(
                "v-btn",
                {
                  attrs: { text: "" },
                  on: {
                    click: function ($event) {
                      _vm.modal = false
                    },
                  },
                },
                [_vm._v("Cancel")]
              ),
              _c("v-spacer"),
              _c(
                "v-btn",
                { attrs: { color: "primary" }, on: { click: _vm.saveTime } },
                [_vm._v("Ok")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }