var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    [
      _c(
        "v-expansion-panels",
        { attrs: { mandatory: "" } },
        [
          _c(
            "v-expansion-panel",
            [
              _c("v-expansion-panel-header", [
                _c("div", { staticClass: "header-text" }, [
                  _vm._v("Trip Time Messages"),
                ]),
              ]),
              _c("v-expansion-panel-content", [_c("trip-time-messages")], 1),
            ],
            1
          ),
          _c(
            "v-expansion-panel",
            [
              _c("v-expansion-panel-header", [
                _c("div", { staticClass: "header-text" }, [
                  _vm._v("Blocked Dates"),
                ]),
              ]),
              _c("v-expansion-panel-content", [_c("blocked-dates")], 1),
            ],
            1
          ),
          _c(
            "v-expansion-panel",
            [
              _c("v-expansion-panel-header", [
                _c("div", { staticClass: "header-text" }, [
                  _vm._v("Special Dates"),
                ]),
              ]),
              _c("v-expansion-panel-content", [_c("special-dates")], 1),
            ],
            1
          ),
          _c(
            "v-expansion-panel",
            [
              _c("v-expansion-panel-header", [
                _c("div", { staticClass: "header-text" }, [
                  _vm._v("Lead Days"),
                ]),
              ]),
              _c("v-expansion-panel-content", [_c("lead-days")], 1),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }